
import { reactive, defineComponent, computed, ref, watch } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { translate } from "@/core/helpers/translate";
import {
  showErrorPopup,
  showSuccessPopup,
  useQuery,
  useMutateQuery,
} from "@/core/helpers/common-helper";
import moment from 'moment';
import { checkPermission } from '@/router/authentication';
import ApiService from "@/core/services/ApiService";
import useGatewayDetail from "@/core/services/compositions/gateway/useGatewayDetail";

interface EditGateway {
  name: string;
  email: string;
  phone: string;
}

interface EditConfig {
  base_url: string;
  enable: boolean;
  provider: string;
  secret_key: string;
  timeout: string;
  token: string;
}

export default defineComponent({
  components: {
    // Pagination,
    // TableSkeleton,
  },
  setup() {
    // init
    const { t } = useI18n();
    const route = useRoute();
    const dialogGatewayVisible = ref(false);
    const dialogConfigVisible = ref(false);
    const dialogCredentialsVisible = ref(false);
    const progressUpdate = ref(false);
    const submitButton = ref<null | HTMLButtonElement>(null);
    const { query } = useQuery();

    // breadcrumb
    setNewPageBreadcrumbs(t("management.client.title"), [
      { title: t("management.client.title") }
    ]);

    const editgateway = reactive<EditGateway>({
      name: '',
      email: '',
      phone: '',
    });

    const editConfig = reactive<EditConfig>({
      base_url: '',
      enable: false,
      provider: '',
      secret_key: '',
      timeout: '',
      token: '',
    });

    const gatewayHash = route.params.id;

    const { data: detail, mutate: refreshList } = useGatewayDetail(gatewayHash);

    watch(detail, (response) => {
      if (response?.rc == 'SUCCESS') {
        editgateway.name = response?.data.name;
        editgateway.email = response?.data.email;
        editgateway.phone = response?.data.phone;

        editConfig.base_url = response?.data.config.base_url;
        editConfig.enable = response?.data.config.enable;
        editConfig.provider = response?.data.config.provider;
        editConfig.secret_key = response?.data.config.secret_key;
        editConfig.timeout = response?.data.config.timeout;
        editConfig.token = response?.data.config.token;
      }
    });

    const showDialogGateway = () => {
      dialogGatewayVisible.value = true;
    }

    const showDialogConfig = () => {
      dialogConfigVisible.value = true;
    }

    const submitUpdateGateway = () => {
      dialogGatewayVisible.value = false;

      if (editgateway.name == null || editgateway.name == '') {
        showErrorPopup({
          title: 'Error',
          text: '[Transaction Note] cannot empty',
          message: '[Transaction Note] cannot empty',
        })

        return;
      }

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        name: editgateway.name,
        email: editgateway.email,
        phone: editgateway.phone,
      };

      ApiService.setHeader();
      ApiService.put(
        `app/entity/gateway/${gatewayHash}`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Update Status',
            text: 'Success'
          });

          // refreshList();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const submitUpdateConfig = () => {
      dialogConfigVisible.value = false;

      if (editConfig.base_url == null || editConfig.base_url == '') {
        showErrorPopup({
          title: 'Error',
          text: '[Base URL] cannot empty',
          message: '[Base URL] cannot empty',
        })

        return;
      }

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        base_url: editConfig.base_url,
        enable: editConfig.enable,
        provider: editConfig.provider,
        secret_key: editConfig.secret_key,
        timeout: editConfig.timeout,
      };

      if (editConfig.token != null && editConfig.token != '') {
        payload['token'] = editConfig.token
      }

      ApiService.setHeader();
      ApiService.patch(
        `app/entity/gateway/${gatewayHash}`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Update Status',
            text: 'Success'
          });

          // refreshList();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    return {
      moment,
      checkPermission,
      detail,
      dialogGatewayVisible,
      dialogConfigVisible,
      translate,
      editConfig,
      submitUpdateConfig,
      submitButton,
      dialogCredentialsVisible,
      progressUpdate,
      editgateway,
      showDialogGateway,
      showDialogConfig,
      submitUpdateGateway
    };
  },
});
